import {htmlToReact, withPrefix} from '../utils';

import {Layout} from '../components/index';
import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default (props) => {
    return (
        <Layout {...props}>
          <div className="inner outer">
            <article className="post post-full">
              <header className="post-header inner-sm">
                <h1 className="post-title line-top">{_.get(props, 'pageContext.frontmatter.title', null)}</h1>
                {_.get(props, 'pageContext.frontmatter.subtitle', null) && (
                <div className="post-subtitle">
                  {htmlToReact(_.get(props, 'pageContext.frontmatter.subtitle', null))}
                </div>
                )}
              </header>
              {_.get(props, 'pageContext.frontmatter.image', null) && (
              <div className="post-image">
                <img src={withPrefix(_.get(props, 'pageContext.frontmatter.image', null))} alt={_.get(props, 'pageContext.frontmatter.title', null)} />
              </div>
              )}
              <div className="post-content inner-sm">
                {htmlToReact(_.get(props, 'pageContext.html', null))}
                
              </div>              
            </article>
          </div>
        </Layout>
    );
    
}
